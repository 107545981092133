import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div<{
  light?: boolean
  grey?: boolean
  continueRight?: boolean
  sharp?: boolean
}>`
  position: relative;
  border-radius: 50px;
  ${(props) =>
    props.light &&
    css`
      background-color: ${({ theme }) => theme.color.light};
      box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    `}

  ${(props) =>
    props.grey &&
    css`
      background-color: rgba(214, 225, 253, 0.24);
    `}

    ${(props) =>
    props.continueRight &&
    css`
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      &:after {
        content: '';
        position: absolute;
        left: 100%;
        top: 0px;
        bottom: 0px;
        width: 2000px;
        background-color: rgba(214, 225, 253, 0.24);
      }
    `}

    ${(props) =>
    props.sharp &&
    css`
      border-radius: 0;
    `}
`

interface BlockProps {
  children: ReactNode
  grey?: boolean
  light?: boolean
  continueRight?: boolean
  className?: string
  style?: Object
  sharp?: boolean
}

const Block = ({
  light,
  grey,
  continueRight,
  children,
  className,
  style,
  sharp,
}: BlockProps) => (
  <Wrapper
    light={light}
    grey={grey}
    continueRight={continueRight}
    className={className}
    style={style}
    sharp={sharp}
  >
    {children}
  </Wrapper>
)

export default Block
