import React, { useState } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'
import Plaatjie from '@ubo/plaatjie'

// Third party CSS
import 'swiper/css'
import 'swiper/css/pagination'

// Components
import ParseContent from 'components/shared/ParseContent'
import Block from 'components/elements/Blocks/BlockDefault'
import NavLink from 'components/shared/NavLink'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

// Images
import Arrow from 'img/arrow-rounded.inline.svg'

// Interface
import { HighlightedProps } from 'components/flex/Posts/Highlighted/HighlightedShell'

const Section = styled.section``

const Blog = ({ fields }: HighlightedProps) => {
  const {
    allWpPost,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.highlightedBlogQueryQuery>(graphql`
    query highlightedBlogQuery {
      allWpPost(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
    }
  `)

  SwiperCore.use([Pagination])

  const [currentIndex, setCurrentIndex] = useState(-1)

  const posts: unknown = allWpPost.edges
  const postIds = fields.blogrelation?.map((post) => post?.databaseId)

  return (
    <Section className="position-relative mb-5">
      <BlogBink
        posts={posts as BlogBinkPosts}
        id="highlighted-blog"
        limit={Infinity}
        showIds={postIds as Array<number>}
      >
        <div className="py-5">
          <Grid
            fields={fields}
            setCurrentIndex={setCurrentIndex}
            currentIndex={currentIndex}
          />
        </div>
      </BlogBink>
    </Section>
  )
}

const SwiperContainer = styled.div<{ marginLeft: number }>`
  max-width: initial !important;
  position: relative;
  width: calc(100% - ${({ marginLeft }) => marginLeft}px + 0.75rem) !important;
  margin-left: ${({ marginLeft }) => marginLeft}px !important;

  & .swiper {
    padding-right: 1.5rem;
  }
`

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.primary};
`

interface GridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_HighlightedPostTypes
  // eslint-disable-next-line no-unused-vars
  setCurrentIndex: (index: number) => void
  currentIndex: number
}

const Grid: React.FC<GridProps> = ({
  fields,
  setCurrentIndex,
  currentIndex,
}) => {
  const blogBink = useBlogBink()

  const [marginLeft, setMarginLeft] = React.useState<number | null>(null)
  const containerRef = React.useRef(null)

  const handleResize = () => {
    if (!containerRef.current) {
      return
    }

    const nodeStyle = window.getComputedStyle(containerRef.current)
    const nodeMarginRight = nodeStyle.getPropertyValue('margin-left')

    setMarginLeft(Number(nodeMarginRight.replace('px', '')))
  }

  React.useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', () => handleResize)
    }
  }, [])

  return (
    <>
      <div className="container" ref={containerRef}>
        <Content content={fields.description} className="mb-5" />
        <div className="row justify-content-center d-none d-lg-flex gx-5  ">
          {blogBink.posts.map((post, index) => {
            const { node }: any = post

            return (
              <div
                key={post.node.databaseId}
                className="col-6 col-md-4 mb-4 mb-md-0"
              >
                <Post
                  node={node}
                  currentIndex={currentIndex}
                  setCurrentIndex={setCurrentIndex}
                  index={index}
                />
              </div>
            )
          })}
          <div className="d-flex justify-content-center mt-5">
            <ButtonPrimary to={fields.link?.url || '#'}>
              {fields.link?.title}
            </ButtonPrimary>
          </div>
        </div>
      </div>
      {marginLeft !== null && (
        <SwiperContainer
          className="container d-lg-none"
          marginLeft={marginLeft}
        >
          <Swiper
            breakpoints={{
              768: {
                slidesPerView: 2.25,
                spaceBetween: 30,
              },
              450: {
                slidesPerView: 1.75,
                spaceBetween: 20,
              },
              0: {
                slidesPerView: 1.25,
                spaceBetween: 10,
              },
            }}
          >
            {blogBink.posts.map((post, index) => {
              const { node }: any = post

              return (
                <SwiperSlide key={post.node.id}>
                  <Post
                    key={post.node.databaseId}
                    node={node}
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                    index={index}
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </SwiperContainer>
      )}
    </>
  )
}

const StyledPost = styled.div`
  @media (min-width: 768px) {
    border-radius: 50px;
  }

  @media (max-width: 767.98px) {
    border-radius: 35px;
  }
`

const Image = styled(Plaatjie)`
  /* border-radius: 50px; */
`

const Title = styled.div`
  /* border-radius: 50px; */
  bottom: 0;

  @media (min-width: 576px) {
    background: linear-gradient(
      180deg,
      rgba(138, 146, 255, 0) 31.77%,
      #636eff 80.21%
    );

    & h2 {
      font-size: ${({ theme }) => theme.font.size.big};
    }
  }

  @media (max-width: 991px) {
    & h2 {
      font-size: ${({ theme }) => theme.font.size.medium};
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }

    & svg {
      width: 30px;
      height: 30px;
    }
  }

  @media (max-width: 575px) {
    background: linear-gradient(
      180deg,
      rgba(138, 146, 255, 0) 11.77%,
      #636eff 80.21%
    );

    & svg {
      width: 20px;
      height: 20px;
    }
  }
`

interface PostProps {
  node: {
    title: string
    uri: string
    recap: {
      thumbnail: any
    }
  }
  // eslint-disable-next-line no-unused-vars
  setCurrentIndex: (index: number) => void
  currentIndex: number
  index: number
}

const Post: React.FC<PostProps> = ({
  node,
  setCurrentIndex,
  currentIndex,
  index,
}) => (
  <StyledPost className="overflow-hidden">
    <motion.div
      onHoverStart={() => setCurrentIndex(index)}
      onHoverEnd={() => setCurrentIndex(-1)}
    >
      <NavLink to={node.uri}>
        <Block className="position-relative">
          <motion.div
            initial={{ scale: 1.1 }}
            animate={currentIndex === index ? { scale: 1 } : { scale: 1.1 }}
          >
            <Image
              image={node.recap.thumbnail}
              alt=""
              className="w-100 h-100 overflow-hidden"
              loading="eager"
            />
          </motion.div>
          <Title className="d-flex align-items-end position-absolute px-sm-4 px-3 py-3 h-100">
            <div className="d-flex flex-row align-items-center">
              <h2 className="text-white me-sm-3">{node.title}</h2>
              <motion.div
                initial={{ rotate: 0 }}
                animate={
                  currentIndex === index ? { rotate: 90 } : { rotate: 0 }
                }
              >
                <Arrow className="rounded-circle" />
              </motion.div>
            </div>
          </Title>
        </Block>
      </NavLink>
    </motion.div>
  </StyledPost>
)

export default Blog
