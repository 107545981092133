import React from 'react'
import Plaatjie from '@ubo/plaatjie'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'

// Components
import ParseContent from 'components/shared/ParseContent'
import Block from 'components/elements/Blocks/BlockDefault'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ButtonSecondary from 'components/elements/Buttons/ButtonSecondary'
import Circle from 'components/elements/Vectors/Circle'

// Interface
import { HighlightedProps } from 'components/flex/Posts/Highlighted/HighlightedShell'

const Section = styled.section``

const Case = ({ fields }: HighlightedProps) => {
  const {
    allWpCase,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.highlightedCaseQueryQuery>(graphql`
    query highlightedCaseQuery {
      allWpCase(sort: { order: ASC, fields: date }) {
        edges {
          node {
            ...generalCaseFragment
          }
        }
      }
    }
  `)

  const cases: unknown = allWpCase.edges

  return (
    <Section className="position-relative mb-5 pb-lg-5">
      <Circle
        identifier="home-small-highlighted-case-circle"
        absolute
        top={15}
        left={2}
        width={108}
        height={108}
        strokeWidth={20}
      />
      <Circle
        identifier="home-highlighted-case-circle"
        absolute
        bottom={15}
        left={-16}
        width={283}
        height={283}
      />
      <BlogBink
        posts={cases as BlogBinkPosts}
        id="highlighted-case"
        limit={Infinity}
        showIds={fields?.caserelation?.map(({ databaseId }: any) => databaseId)}
      >
        <Grid fields={fields} />
      </BlogBink>
    </Section>
  )
}

const Excerpt = styled(ParseContent)`
  color: ${({ theme }) => theme.color.dark};
  & p {
    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.big};
    }
  }
`

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.primary};
`

const Grid: React.FC<HighlightedProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="container">
      <div className="row justify-content-end">
        <div className="position-relative col-lg-10 mt-5 pt-4 pt-md-0">
          <Block grey continueRight className="px-4 px-sm-5 py-5">
            <div className="ps-lg-5 py-lg-4">
              <div className="ps-lg-5">
                <div className="ps-lg-5">
                  <div className="ps-lg-4">
                    <Content content={fields.description} className="mb-4" />
                    {blogBink.posts.map((post) => {
                      const { node }: any = post

                      return (
                        <Post
                          key={post.node.databaseId}
                          // @ts-ignore
                          node={node}
                          blogFields={fields}
                        />
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Block>
        </div>
      </div>
    </div>
  )
}

const Thumbnail = styled.div`
  background-color: ${({ theme }) => theme.color.grey};

  @media (min-width: 992px) {
    top: calc(50% - 187.5px);
    left: -200.5px;
    width: 375px;
    height: 375px;
  }

  @media (max-width: 991px) {
    top: -105px;
    right: 0;
    width: 175px;
    height: 175px;
  }

  @media (max-width: 767px) {
    top: -145px;
    right: 0;
    width: 175px;
    height: 175px;
  }
`

const Image = styled(Plaatjie)`
  width: 100%;
  & img {
    object-fit: contain !important;
  }

  @media (min-width: 992px) {
    height: 310px;
    margin: 3rem;
  }

  @media (max-width: 991px) {
    height: 160px;
    margin: 1rem;
  }

  @media (max-width: 767px) {
    height: 140px;
  }
`

const Post: React.FC<HighlightedProps> = ({ node, blogFields }: any) => (
  <>
    <Thumbnail className="position-absolute rounded-circle d-flex justify-content-center align-items-center">
      <Image
        image={node.recap.thumbnail}
        alt=""
        className="position-relative"
      />
    </Thumbnail>
    <Excerpt content={node.recap.excerpt} />
    <div className="mt-5">
      <ButtonPrimary to={node.uri} className="me-4 mb-4 mb-lg-0">
        {blogFields.readmoretext}
      </ButtonPrimary>
      <ButtonSecondary to={blogFields.link.url}>
        {blogFields.link.title}
      </ButtonSecondary>
    </div>
  </>
)

export default Case
