import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'

// Components
import ParseContent from 'components/shared/ParseContent'
import NavLink from 'components/shared/NavLink'

// Images
import Arrow from 'img/arrow-rounded.inline.svg'

// Interface
import { HighlightedProps } from 'components/flex/Posts/Highlighted/HighlightedShell'

const Section = styled.section`
  @media (min-width: 992px) {
    padding-bottom: 6rem;
  }
`

const Vacancy = ({ fields }: HighlightedProps) => {
  const {
    allWpVacancy,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.highlightedVacancyQueryQuery>(graphql`
    query highlightedVacancyQuery {
      allWpVacancy(sort: { order: ASC, fields: date }) {
        edges {
          node {
            ...generalVacancyFragment
          }
        }
      }
    }
  `)

  const vacancies: unknown = allWpVacancy.edges

  return (
    <Section className="position-relative mb-5" id="vacatures">
      <BlogBink
        posts={vacancies as BlogBinkPosts}
        id="highlighted-vacancy"
        limit={Infinity}
        showIds={fields?.vacancyrelation?.map(
          ({ databaseId }: any) => databaseId
        )}
      >
        <Grid fields={fields} />
      </BlogBink>
    </Section>
  )
}

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.primary};
`

const Grid: React.FC<HighlightedProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="container h-100">
      <Content content={fields.description} className="mb-4" />
      <div className="row justify-content-center h-100">
        {blogBink.posts.map((post) => {
          const { node }: any = post

          return (
            <div key={post.node.databaseId} className="col-md-4 col-12 h-100">
              <Post
                // @ts-ignore
                node={node}
                blogFields={fields}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

const StyledVacancy = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  border-radius: 50px;
  color: ${({ theme }) => theme.color.light} !important;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    aspect-ratio: 1/1;

    & .vacancy-icon {
      bottom: 35px;
      right: 35px;
    }
  }

  @media (min-width: 1400px) {
    padding: 2rem 4rem;
  }

  @media (max-width: 991.98px) {
    padding: 1rem;

    & h2 {
      font-size: ${({ theme }) => theme.font.size.big};
    }

    & svg {
      width: 30px;
      height: 30px;
    }
  }

  @media (max-width: 767.98px) {
    & .vacancy-icon {
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
    }

    & h2 {
      font-size: ${({ theme }) => theme.font.size.medium};
    }
  }
`

const Background = styled.div`
  border-radius: 50px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(138, 146, 255, 0) 31.77%,
    #636eff 80.21%
  );
  mix-blend-mode: multiply;
`

const ArrowWrapper = styled(motion.div)`
  @media (min-width: 768px) {
    bottom: 35px;
    right: 35px;
  }
  @media (max-width: 767.98px) {
    bottom: 10.5px;
    right: 22.5px;
  }
  @media (max-width: 575.98px) {
    bottom: 10px;
    right: 18px;
    & svg {
      width: 30px;
      height: 30px;
    }
  }
`

const Post: React.FC<HighlightedProps> = ({ node }: any) => (
  <StyledVacancy className="position-relative d-flex align-items-center h-100 mb-4 mb-lg-0">
    <NavLink to={node.uri}>
      <Background className="position-absolute" />
      <div className="position-relative" style={{ zIndex: 4 }}>
        <h2 className="text-center text-white font-weight-bold px-md-2 ps-2 pe-5 mb-0 py-md-0 py-1">
          {node.title}
        </h2>
      </div>
      <ArrowWrapper className="position-absolute">
        <div className="d-flex align-items-center">
          <Arrow className="rounded-circle" />
        </div>
      </ArrowWrapper>
    </NavLink>
  </StyledVacancy>
)

export default Vacancy
